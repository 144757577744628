<template>
  <div class="payment-online-item">
    <van-cell title="客户名称" :value="payment.client?.name" />
    <van-cell
      :value-class="payment.payment_online ? 'finished' : 'unfinished'"
      title="状态"
      :value="payment.payment_online ? `已付款(￥${payment.payment_online.amount})` : '未付款'"
    />
    <van-cell v-if="payment.payment_online" title="付款方式" :value="payment.payment_online.type | paymentOnlineTypeFilter" />
    <van-cell value-class="amount" title="金额" :value="`￥${payment.wxpay_amount}`" />
    <van-cell v-if="!payment.payment_online_id" title="查看收款码" center is-link @click="$emit('click', payment)" />
    <van-cell v-if="!payment.payment_online_id" title="修改付款方式" center is-link @click="$emit('pay-type', payment)" />
  </div>
</template>

<script>
const paymentOnlineTypeMaps = {
  1: '微信',
  2: '支付宝'
}
export default {
  filters: {
    paymentOnlineTypeFilter(type) {
      return paymentOnlineTypeMaps[type]
    }
  },
  props: {
    payment: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .payment-online-item {
    margin-bottom: 10px;
  }
  .amount {
    color: #ee0a24;
  }
  .finished {
    color: #07c160;
  }
  .unfinished {
    color: #faab0c;
  }
</style>
