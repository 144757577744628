<template>
  <div class="bill-check-payment-online">
    <my-nav-bar
      title="线上支付实收"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list ref="list" v-model="loading" class="list" :finished="finished" @load="getList">
        <div v-if="bill_type === '2'">
          <payment-online-item v-for="(payment, index) in list" :key="index" :payment="payment" @click="handleShowPaymentQrcode" @pay-type="handleModifyPayType" />
        </div>
        <div v-else>
          <payment-online-item-store v-for="(payment, index) in list" :key="index" :payment="payment" />
        </div>
      </van-list>
    </div>
    <my-dialog
      v-model="showPaymentQrcodeDialog"
      title="收款码"
      :show-cancel-button="false"
    >
      <div class="payment-qrcode">
        <van-image :src="qrcodeData" />
      </div>
    </my-dialog>
    <van-action-sheet v-model="showPayTypeActionSheet" :actions="payTypeActions" @select="handlePayTypeSelected" />
    <van-action-sheet v-model="showOrderActionSheet" title="选择签单">
      <van-checkbox-group v-model="selectedOrderIds">
        <van-cell v-for="order in orders" :key="order.id" :title="order.num" @click="onOrderSelect(order.id)">
          <div class="order-checkbox">
            <van-checkbox :ref="`orderCheckbox${order.id}`" :name="order.id" />
          </div>
        </van-cell>
        <div class="order-confirm-btn"><van-button type="primary" round @click="onOrderSelectConfirm">确定</van-button></div>
      </van-checkbox-group>
    </van-action-sheet>
  </div>
</template>

<script>
import MyNavBar from '@/components/my-nav-bar'
import PaymentOnlineItem from './components/payment-online-item'
import PaymentOnlineItemStore from './components/payment-online-item-store'
import { getPaymentOnlines, modifyPayType, getOrders } from '@/api/bill_check'
import qrcode from 'qrcode'
import MyDialog from '@/components/my-dialog'
export default {
  components: { MyNavBar, PaymentOnlineItem, PaymentOnlineItemStore, MyDialog },
  data() {
    return {
      bill_id: this.$route.query.bill_id,
      bill_type: this.$route.query.bill_type,
      loading: false,
      finished: false,
      showEmpty: false,
      listQuery: {
        page: 0,
        bill_id: this.$route.query.bill_id
      },
      list: [],
      showPaymentQrcodeDialog: false,
      qrcodeData: null,
      currentPayment: null,
      showPayTypeActionSheet: false,
      payTypeActions: [
        { name: '现金', value: 1 },
        { name: '转账', value: 2 },
        { name: '签单', value: 3 }
      ],
      orders: null,
      showOrderActionSheet: false,
      selectedOrderIds: []
    }
  },
  methods: {
    reload() {
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.listQuery.page = 0
      this.$refs.list.check()
    },
    getList() {
      this.loading = true
      this.listQuery.page++
      getPaymentOnlines(this.listQuery).then(res => {
        this.loading = false
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
        this.finished = res.data.list.length < 10
      }).catch(() => {
        this.loading = false
        this.finished = true
        this.showEmpty = true
      })
    },
    handleShowPaymentQrcode(payment) {
      const url = process.env.VUE_APP_PAYMENT_URL + '?order_num=' + payment.order_num
      qrcode.toDataURL(url, { width: 200 }, (err, data) => {
        if (err) {
          this.fail('生成付款二维码失败')
          console.log(err)
          return
        }
        this.showPaymentQrcodeDialog = true
        this.qrcodeData = data
      })
    },
    handleModifyPayType(payment) {
      this.currentPayment = payment
      this.showPayTypeActionSheet = true
    },
    handlePayTypeSelected(action) {
      this.showPayTypeActionSheet = false
      if (action.value === 3) {
        // 签单
        this.beginLoad()
        getOrders({ bill_id: this.currentPayment.bill_id, client_id: this.currentPayment.client_id }).then(res => {
          this.endLoad()
          this.orders = res.data
          this.selectedOrderIds = []
          this.showOrderActionSheet = true
        })
      } else {
        this.confirm(`确定要修改付款方式为【${action.name}】吗？`, `${this.currentPayment.client.name}`).then(() => {
          const data = {
            payment_id: this.currentPayment.id,
            modify_pay_type: action.value
          }
          this.beginLoad()
          modifyPayType(data).then(res => {
            this.success(res.msg)
            this.reload()
          })
        })
      }
    },
    onOrderSelect(orderId) {
      const index = this.selectedOrderIds.findIndex(soi => soi === orderId)
      if (index === -1) {
        this.selectedOrderIds.push(orderId)
      } else {
        this.selectedOrderIds.splice(index, 1)
      }
    },
    onOrderSelectConfirm() {
      this.showOrderActionSheet = false
      const data = {
        payment_id: this.currentPayment.id,
        modify_pay_type: 3,
        unpaid_order_ids: this.selectedOrderIds
      }
      this.beginLoad()
      modifyPayType(data).then(res => {
        this.success(res.msg)
        this.$router.back()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .list .payment-online-item:nth-last-child(1) {
    margin-bottom: 0!important;
  }
  .payment-qrcode {
    text-align: center;
  }
  .order-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .order-confirm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    .van-button {
      width: 80%;
    }
  }
</style>
