var render = function () {
  var _vm$payment$client;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-online-item"
  }, [_c('van-cell', {
    attrs: {
      "title": "客户名称",
      "value": (_vm$payment$client = _vm.payment.client) === null || _vm$payment$client === void 0 ? void 0 : _vm$payment$client.name
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": _vm.payment.status ? 'finished' : 'unfinished',
      "title": "状态",
      "value": _vm.payment.payment_online_amount > 0 ? "\u5DF2\u4ED8\u6B3E(\uFFE5".concat(_vm.payment.payment_online_amount, ")") : '未付款'
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "金额",
      "value": "\uFFE5".concat(_vm.payment.wxpay_amount)
    }
  }), _vm.payment.payment_online_amount > 0 ? _c('van-cell', {
    attrs: {
      "title": "明细",
      "label-class": "detail"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return _vm._l(_vm.payment.orders, function (order, index) {
          return _c('span', {
            key: index
          }, [_vm._v(" " + _vm._s("\u3010".concat(order.num, "\u3011")) + " "), _c('span', {
            staticClass: "finished"
          }, [_vm._v(_vm._s("+".concat(order.payment_online.amount, "\u5143")))]), _vm._v("； ")]);
        });
      },
      proxy: true
    }], null, false, 576941401)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }