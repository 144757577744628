<template>
  <div class="payment-online-item">
    <van-cell title="客户名称" :value="payment.client?.name" />
    <van-cell
      :value-class="payment.status ? 'finished' : 'unfinished'"
      title="状态"
      :value="payment.payment_online_amount > 0 ? `已付款(￥${payment.payment_online_amount})` : '未付款'"
    />
    <van-cell value-class="amount" title="金额" :value="`￥${payment.wxpay_amount}`" />
    <van-cell v-if="payment.payment_online_amount > 0" title="明细" label-class="detail">
      <template #label>
        <span v-for="(order, index) in payment.orders" :key="index">
          {{ `【${order.num}】` }}
          <span class="finished">{{ `+${order.payment_online.amount}元` }}</span>；
        </span>
      </template>
    </van-cell>
  </div>
</template>

<script>
const paymentOnlineTypeMaps = {
  1: '微信',
  2: '支付宝'
}
export default {
  filters: {
    paymentOnlineTypeFilter(type) {
      return paymentOnlineTypeMaps[type]
    }
  },
  props: {
    payment: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .payment-online-item {
    margin-bottom: 10px;
  }
  .amount {
    color: #ee0a24;
  }
  .finished {
    color: #07c160;
  }
  .unfinished {
    color: #faab0c;
  }
  .detail {
    word-break: break-all;
  }
</style>
