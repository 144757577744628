var render = function () {
  var _vm$payment$client;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-online-item"
  }, [_c('van-cell', {
    attrs: {
      "title": "客户名称",
      "value": (_vm$payment$client = _vm.payment.client) === null || _vm$payment$client === void 0 ? void 0 : _vm$payment$client.name
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": _vm.payment.payment_online ? 'finished' : 'unfinished',
      "title": "状态",
      "value": _vm.payment.payment_online ? "\u5DF2\u4ED8\u6B3E(\uFFE5".concat(_vm.payment.payment_online.amount, ")") : '未付款'
    }
  }), _vm.payment.payment_online ? _c('van-cell', {
    attrs: {
      "title": "付款方式",
      "value": _vm._f("paymentOnlineTypeFilter")(_vm.payment.payment_online.type)
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "金额",
      "value": "\uFFE5".concat(_vm.payment.wxpay_amount)
    }
  }), !_vm.payment.payment_online_id ? _c('van-cell', {
    attrs: {
      "title": "查看收款码",
      "center": "",
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click', _vm.payment);
      }
    }
  }) : _vm._e(), !_vm.payment.payment_online_id ? _c('van-cell', {
    attrs: {
      "title": "修改付款方式",
      "center": "",
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('pay-type', _vm.payment);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }